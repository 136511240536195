<template>
    <div>
        <b-row>
            <b-col lg="4">
                <b-overlay :show="!dataLoaded">
                    <b-card title="Promotional item" style="overflow: scroll">
                        <BasicOverview v-if="!editEnabled" :data="promotional_item" :definitions="overviewDefinitions"/>
                        <div v-else>
                            <b-form-group>
                                <label>Serial number</label>
                                <b-form-input v-model="editObject.serial_number"/>
                            </b-form-group>

                            <b-form-group>
                                <label>Type</label>
                                <v-select
                                    v-model="editObject.promotional_item_type_id"
                                    :reduce="type => type.id"
                                    label="name"
                                    :options="promotionalItemTypes"
                                />
                            </b-form-group>
                        </div>

                        <hr/>

                        <b-button v-if="!editEnabled && $hasPermission($permissions.EditPromotionalItems)" variant="warning" @click="openEdit" class="mr-1">
                            <feather-icon icon="EditIcon" class="mr-50"/>
                            <span class="align-middle">Edit</span>
                        </b-button>

                        <b-button v-if="!editEnabled && promotional_item.status === 0 && $hasPermission($permissions.AssignPromotionalItems)" variant="success" @click="assignmentModalActive = true" class="float-right mr-1">
                            <feather-icon icon="FilePlusIcon" class="mr-50"/>
                            <span class="align-middle">Assign</span>
                        </b-button>

                        <b-button v-if="!editEnabled && promotional_item.status === 1" variant="danger" @click="removePromotionalItemFromOutlet" class="float-right mr-1">
                            <feather-icon icon="FileMinusItem" class="mr-50"/>
                            <span class="align-middle">Remove from outlet</span>
                        </b-button>

                        <b-button v-if="editEnabled" variant="success" @click="savePromotionalItem" class="mr-1">
                            <feather-icon icon="SaveIcon" class="mr-50"/>
                            <span class="align-middle">Save</span>
                        </b-button>

                        <b-button v-if="editEnabled" variant="danger" @click="editEnabled = false">
                            <feather-icon icon="XIcon" class="mr-50"/>
                            <span class="align-middle">Cancel</span>
                        </b-button>

                        <b-button v-if="editEnabled && promotional_item.status === 2 && $hasPermission($permissions.RemovePromotionalItems)" variant="danger" @click="removePromotionalItem" class="float-right mr-1">
                            <feather-icon icon="Trash2Icon" class="mr-50"/>
                            <span class="align-middle">Remove</span>
                        </b-button>

                        <b-button v-if="editEnabled && promotional_item.status === 0" variant="danger" @click="decommissionPromotionalItem" class="float-right mr-1">
                            <feather-icon icon="DeleteIcon" class="mr-50"/>
                            <span class="align-middle">Decommission</span>
                        </b-button>
                    </b-card>
                </b-overlay>
            </b-col>
        </b-row>
        <b-row>
            <b-col lg="12">
                <PromotionalItemUserRequests :promotional-item-id="$route.params.id"/>
            </b-col>
            <b-col lg="12">
                <SurveyResults :data-url="`/api/management/v1/promotional_item/${  $route.params.id}/survey_results`"/>
            </b-col>
        </b-row>

        <b-modal title="Assign promotional item" v-model="assignmentModalActive" no-close-on-backdrop>
            <template #default>

                <b-form-group>
                    <label>Outlet</label>
                    <v-select
                        v-model="selectedAssignmentOutlet"
                        :reduce="area => area.id"
                        label="name"
                        :options="outlets"
                    />
                </b-form-group>
            </template>

            <template #modal-footer>

                <b-button variant="danger" @click="assignmentModalActive = false">
                    <feather-icon
                        icon="XIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Close</span>
                </b-button>

                <b-button variant="primary" @click="assignPromotionalItem">
                    <feather-icon
                        icon="FilePlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Assign</span>
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>

    import {BButton, BCard, BCol, BFormGroup, BFormInput, BOverlay, BRow} from 'bootstrap-vue'
    import BasicOverview from '@/views/components/BasicOverview'
    import vSelect from 'vue-select'
    import PromotionalItemUserRequests from '@/views/PromotionalItem/PromotionalItemUserRequests'
    import SurveyResults from '@/views/components/SurveyResults'

    export default {
        components: {
            SurveyResults,
            BasicOverview,
            vSelect,
            BCard,
            BOverlay,
            BRow,
            BCol,
            BButton,
            BFormGroup,
            BFormInput,
            PromotionalItemUserRequests
        },
        data() {
            return {
                dataLoaded: false,
                promotional_item: {
                    serial_number: '',
                    promotional_item_type: '',
                    status: 0,
                    outlet: ''
                },

                outlets: [],

                promotionalItemTypes: [],

                statusMap: new Map([
                    [0, {text: 'In warehouse', color: 'primary'}],
                    [1, {text: 'Assigned to outlet', color: 'success'}],
                    [2, {text: 'Decommissioned', color: 'danger'}]
                ]),

                overviewDefinitions: [
                    { key: 'serial_number', name: 'Serial number', type: 0 },
                    { key: 'promotional_item_type', name: 'Type', type: 0 },
                    { key: 'status',
                      name: 'Status',
                      type: 4,
                      formatFn: (data) => {
                          return this.statusMap.get(data).text
                      },
                      colorFn: (data) => {
                          return this.statusMap.get(data).color
                      }
                    },
                    { key: 'outlet', name: 'Outlet', type: 0, formatFn: (data) => ((data.length > 0) ? data : 'Not defined') }
                ],

                editEnabled: false,
                editObject: {},

                assignmentModalActive: false,
                selectedAssignmentOutlet: ''
            }
        },
        methods: {
            openEdit() {
                this.editObject = JSON.parse(JSON.stringify(this.promotional_item))
                this.editEnabled = true
            },
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get(`/api/management/v1/promotional_item/${  this.$route.params.id}`)
                loadPromise.then(function(response) {
                    thisIns.promotional_item = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const promotionalItemTypesPromise = this.$http.get('/api/management/v1/promotional_item_type')
                promotionalItemTypesPromise.then(function(response) {
                    thisIns.promotionalItemTypes = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const outletsLoadPromise = this.$http.get('/api/management/v1/outlet')
                outletsLoadPromise.then(function(response) {
                    thisIns.outlets = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([loadPromise, promotionalItemTypesPromise, outletsLoadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            savePromotionalItem() {
                const thisIns = this
                const savePromise = this.$http.put(`/api/management/v1/promotional_item/${  this.$route.params.id}`, this.editObject)
                savePromise.then(function() {
                    thisIns.$printSuccess('Promotional item saved')
                    thisIns.editEnabled = false
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            async removePromotionalItem() {

                try {

                    const confirmDelete = await this.$confirmDialog('Do you want to remove this promotional item?')
                    
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/management/v1/promotional_item/${  this.$route.params.id}`)
                    this.$printSuccess('Promotional item removed')
                    this.$router.go(-1)
                    
                } catch (error) {
                    this.$printError((error.response) ? error.response.data : error)
                }
            },
            assignPromotionalItem() {
                const thisIns = this
                const assignPromise = this.$http.put(`/api/management/v1/promotional_item/${  this.$route.params.id}/assign/${this.selectedAssignmentOutlet}`)
                assignPromise.then(function() {
                    thisIns.$printSuccess('Promotional item assigned to outlet')
                    thisIns.assignmentModalActive = false
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            async removePromotionalItemFromOutlet() {

                try {

                    const confirmRemoval = await this.$confirmDialog('Do you want to remove this promotional item from outlet?')
                    
                    if (!confirmRemoval) return
                    await this.$http.put(`/api/management/v1/promotional_item/${  this.$route.params.id}/remove_from_outlet`)
                    this.$printSuccess('Promotional item removed from outlet')                    
                } catch (error) {
                    this.$printError((error.response) ? error.response.data : error)
                    
                } finally {
                    this.loadData()
                }
            },
            async decommissionPromotionalItem() {

                try {

                    const confirmDecommission = await this.$confirmDialog('Do you want to decommission this promotional item?')
                    
                    if (!confirmDecommission) return
                    await this.$http.put(`/api/management/v1/promotional_item/${  this.$route.params.id}/decommission`)
                    this.$printSuccess('Promotional item decommissioned')                  
                    this.editEnabled = false

                } catch (error) {
                    this.$printError((error.response) ? error.response.data : error)
                    
                } finally {
                    this.loadData()
                }
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>